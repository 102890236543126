import React from 'react';
import LearnCta from '../components/common/LearnCta';

import Layout from '../components/layout';
import SEO from '../components/seo';

function LearnAbout() {
  return (
    <Layout>
      <SEO
        keywords={[
          `work-life balance`,
          `parent support`,
          `lone parent`,
          `childcare`,
          'ireland',
        ]}
        title='Learn About The Project'
      />

      <LearnCta />
    </Layout>
  );
}

export default LearnAbout;
